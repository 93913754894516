<template>
  <div>
    <div class="Nosotros">
      <img src="@/assets/wenjian.png" class="texti" />
      <div class="right">
        <div class="Punto">Política de Privacidad</div>
        <div class="Xonaca">
          <img src="@/assets/time.png" alt="" />
          <span>Fecha de última actualización: 3 de enero de 2025</span>
        </div>
        <div class="Bienvenido">
          Bienvenido a nuestra aplicación de cálculo de préstamos (en adelante,
          la "Aplicación"). Valoramos su privacidad y nos comprometemos a
          proteger su información personal. Esta Política de Privacidad tiene
          como objetivo ayudarlo a comprender cómo recopilamos, usamos,
          almacenamos, compartimos y protegemos sus datos. Le pedimos que lea
          detenidamente esta política y se asegure de comprenderla.
        </div>
        <div class="desc-title">1. Información que recopilamos</div>
        <div class="desc-content">
          <div class="marginB10">
            Al utilizar esta Aplicación, podemos recopilar los siguientes tipos
            de información:
          </div>
          <div class="marginB10">
            <span class="print">Datos ingresados por el usuario:</span>
            <span>
              Incluye el monto del préstamo, la tasa de interés anual, el plazo
              del préstamo, el método de pago, entre otros.</span
            >
          </div>
          <div class="marginB10">
            <span class="print">Información del dispositivo:</span>
            <span>
              Podemos recopilar y registrar identificadores de su dispositivo,
              dirección IP y la versión del sistema operativo del dispositivo
              para optimizar las funciones de la Aplicación y mejorar la
              experiencia del usuario.</span
            >
          </div>
          <div class="marginB10">
            <span class="print">Ubicación aproximada:</span>
            <span>
              Recopilamos su ubicación aproximada para ajustar las tasas de
              interés y las fluctuaciones de acuerdo con su zona geográfica, de
              manera que podamos ofrecerle el plan de préstamo más
              adecuado.</span
            >
          </div>
          <div class="marginB10">
            <span class="print">Información de la agenda de contactos:</span>
            <span>
              Podemos acceder a la información de su agenda de contactos para
              ayudarle a compartir el plan de préstamo o nuestra aplicación con
              sus amigos y familiares.</span
            >
          </div>
          <div class="marginB10">
            <span class="print">Permiso de cámara：</span>
            <span>
              Necesitamos acceder a los permisos de su cámara para que pueda
              tomar fotos y cargar problemas relacionados, lo que nos ayudará a
              entender y resolver su problema más rápidamente.</span
            >
          </div>
          <div class="marginB10">
            <span class="print">Identificador de Publicidad (IDFA)：</span>
            <span>
              Al utilizar esta aplicación, es posible que recopilemos y
              utilicemos su Identificador de Publicidad (IDFA), que es un
              identificador único proporcionado por Apple para el seguimiento de
              publicidad. Usamos el IDFA para mostrar anuncios personalizados,
              analizar el uso de la aplicación y mejorar la experiencia del
              usuario. Nos comprometemos a utilizar el IDFA únicamente con fines
              publicitarios y no para ningún otro propósito.</span
            >
          </div>
        </div>
        <div class="desc-title">2. Uso de la información</div>
        <div class="desc-content">
          <div class="marginB10">
            La información recopilada se utilizará únicamente para los
            siguientes fines:
          </div>
          <div class="marginB10">
            <span class="print" style="font-weight: 400"
              >Generar y mostrar su plan de pagos del préstamo;</span
            >
          </div>
          <div class="marginB10">
            <span class="print" style="font-weight: 400"
              >Proporcionar cálculos precisos del préstamo y consejos
              financieros;</span
            >
          </div>
          <div class="marginB10">
            <span class="print" style="font-weight: 400"
              >Ajustar las tasas de interés y las fluctuaciones según su
              ubicación geográfica y otras condiciones aplicables;</span
            >
          </div>
          <div class="marginB10">
            <span class="print" style="font-weight: 400"
              >Permitirle compartir fácilmente el plan de préstamo o la
              aplicación con sus contactos;</span
            >
          </div>
          <div class="marginB10">
            <span class="print" style="font-weight: 400"
              >Mejorar las funciones de la aplicación y la experiencia del
              usuario.</span
            >
          </div>
          <div class="marginB10">
            <span class="print" style="font-weight: 400"
              >Cargue fotos para describir el problema al proporcionar
              comentarios.</span
            >
          </div>
          <div>
            Nos comprometemos a no utilizar su información personal para ningún
            otro propósito no autorizado.
          </div>
        </div>
        <div class="desc-title">3. Almacenamiento y protección de datos</div>
        <div class="desc-content">
          <div class="marginB10">
            <span class="print" style="font-weight: 400"
              >Todos los datos personales recopilados serán almacenados de forma
              segura en nuestros servidores.</span
            >
          </div>
          <div class="marginB10">
            <span class="print" style="font-weight: 400"
              >Implementamos medidas técnicas y administrativas estrictas, como
              cifrado de datos y control de acceso, para garantizar la seguridad
              de sus datos y prevenir el acceso no autorizado, divulgación o
              pérdida.</span
            >
          </div>
          <div class="marginB10">
            <span class="print" style="font-weight: 400"
              >No utilizaremos sus datos para ningún otro propósito sin su
              consentimiento explícito.</span
            >
          </div>
        </div>
        <div class="desc-title">4. Compartición de datos</div>
        <div class="desc-content">
          <div class="marginB10">
            No compartiremos su información personal con terceros, salvo en los
            siguientes casos:
          </div>
          <div class="marginB10">
            <span class="print">Requisitos legales:</span>
            <span>
              En caso de que sea necesario divulgar su información personal para
              cumplir con la ley, regulaciones, solicitudes gubernamentales o
              procedimientos judiciales;</span
            >
          </div>
          <div class="marginB10">
            <span class="print">Con su consentimiento:</span>
            <span>
              En caso de que obtengamos su consentimiento explícito para
              compartir sus datos con terceros;</span
            >
          </div>
          <div class="marginB10">
            <span class="print">Socios comerciales:</span>
            <span>
              Para mejorar nuestros servicios, podemos compartir sus datos con
              socios confiables que se comprometan a cumplir con los mismos
              estándares de protección de privacidad.</span
            >
          </div>
        </div>
        <div class="desc-title">5. Derechos del usuario</div>
        <div class="desc-content">
          <div class="marginB10">Usted tiene los siguientes derechos:</div>
          <div class="marginB10">
            <span class="print">Acceso y modificación:</span>
            <span>
              Puede acceder a su información personal en cualquier momento y
              modificarla;</span
            >
          </div>
          <div class="marginB10">
            <span class="print">Eliminación de datos:</span>
            <span>
              Puede solicitar la eliminación o anonimización de toda la
              información personal almacenada en nuestros servidores;</span
            >
          </div>
          <div class="marginB10">
            <span class="print">Revocación del consentimiento:</span>
            <span>
              Puede revocar en cualquier momento el consentimiento que nos haya
              dado para utilizar sus datos. Una vez revocado, dejaremos de
              recopilar y usar sus datos personales y los eliminaremos de
              acuerdo con las leyes aplicables;</span
            >
          </div>
          <div class="marginB10">
            <span class="print">Gestión de permisos de contactos:</span>
            <span>
              Puede gestionar o revocar el acceso a su agenda de contactos en la
              configuración de su dispositivo. Si revoca este permiso, podrá
              seguir utilizando las demás funciones de la aplicación, pero
              algunas funciones pueden estar limitadas.</span
            >
          </div>
          <div class="marginB10">
            <span class="print">Control de permisos de la cámara:</span>
            <span>
              Tiene la posibilidad de habilitar o deshabilitar el acceso a la
              cámara desde la configuración de su dispositivo. Si decide
              desactivar este permiso, podrá seguir utilizando la mayoría de las
              funciones de la aplicación, aunque algunas funcionalidades
              vinculadas a la cámara podrían no estar disponibles.</span
            >
          </div>
          <div class="marginB10">
            <span class="print">Identificador de Publicidad (IDFA):</span>
            <span>
              Usted puede gestionar el acceso a su IDFA en cualquier momento
              desde la configuración de su dispositivo.</span
            >
          </div>
        </div>
        <div class="desc-title">6. Retención de datos</div>
        <div class="desc-content">
          <div class="marginB10">
            Conservaremos sus datos según sea necesario para su uso y conforme a
            los requisitos legales.
          </div>
          <div>
            Una vez que los datos hayan superado el período de retención
            necesario, o cuando solicite la eliminación de datos, los
            eliminaremos o anonimizaremos de acuerdo con las leyes aplicables.
          </div>
        </div>
        <div class="desc-title">7. Contáctenos</div>
        <div class="desc-content">
          <div class="marginB10">
            Si tiene alguna pregunta o inquietud sobre esta Política de
            Privacidad o desea ejercer sus derechos, puede ponerse en contacto
            con nosotros a través de:
          </div>
          <div class="print marginB10" style="font-weight: 400">
            Correo electrónico:
            <span style="color: #336df4">servicio@textipunto.com</span>
          </div>
          <div class="print" style="font-weight: 400">
            Horario de atención: 24 horas al día, todos los días
          </div>
        </div>
        <div class="desc-title">
          8. Actualizaciones de la Política de Privacidad
        </div>
        <div class="desc-content">
          Podemos actualizar esta Política de Privacidad según los requisitos
          legales, avances tecnológicos o para mejorar nuestros servicios. Cada
          vez que realicemos una actualización, la versión más reciente estará
          disponible en la aplicación y se actualizará la fecha de "Última
          actualización". Le recomendamos revisar periódicamente esta política
          para mantenerse informado sobre cómo protegemos su información.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Privacid',
  components: {},
  data() {
    return {
      index: 1,
    };
  },
  methods: {},
};
</script>

<style scoped>
.Nosotros {
  padding: 40px 360px;
  background-color: white;
  display: flex;
}
.texti {
  width: 294px;
  height: 440px;
  margin-right: 40px;
}
.right img {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}
.Punto {
  font-weight: 600;
  font-size: 32px;
  color: #565656;
  margin-bottom: 10px;
}
.Xonaca {
  font-weight: 400;
  font-size: 20px;
  color: #6450f0;
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}
.Bienvenido {
  font-size: 20px;
  margin-bottom: 20px;
  color: #565656;
  line-height: 32px;
}
.desc-title {
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 5px;
  color: #565656;
}
.desc-content {
  font-size: 20px;
  color: #565656;
  margin-bottom: 10px;
  line-height: 32px;
}

.marginB10 {
  margin-bottom: 10px;
}
.marginB10 span {
}
.print {
  position: relative;
  padding-left: 30px;
  font-weight: bold;
}
.print::after {
  content: ' ';
  width: 8px;
  height: 8px;
  background-color: black;
  position: absolute;
  left: 5px;
  top: 9px;
  /* transform: translateY(-50%); */
  border-radius: 50%;
}
</style>
